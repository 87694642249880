<template>
  <div style="background: rgb(253, 243, 227); height: 100%">
    <img
      v-if="adsList == undefined || adsList.length == 0"
      style="margin-bottom: 10px;width: 100%"
      src="https://img.alicdn.com/imgextra/i2/573804794/O1CN0103Brcu1lHhRiqwa2o_!!573804794.png"
    />
    <van-swipe v-else class="my-swipe" :autoplay="2600" indicator-color="white" lazy-render style="margin-bottom: 10px;">
    <a :href="item.url" v-for="(item, index) in adsList" :key="index" >
      <van-swipe-item style="width: 100vw;">
          <img
        style="width: 100vw"
        :src="item.pic"
      />
      </van-swipe-item>
      </a>
    </van-swipe>
    <van-sticky>
      <van-tabs v-model:active="active" @change="clickTabs">
        <van-tab
          v-for="(info, index) in pageData.type"
          :title="info.title"
          :key="index"
        >
        </van-tab>
      </van-tabs>
    </van-sticky>
    <van-index-bar ref="tabsBar" sticky-offset-top="60" :sticky="false" highlight-color="" z-index="99">
    <div
            style="width: 90%;border-radius: 10px;padding: 10px;margin: 12px auto 0px;"
            :style="index2 == 0?'background:rgb(252,152,42)':'background: rgb(58, 56, 57)'"
            v-for="(platform, index2) in pageData.all"
          :key="index2"
          >
            <div style="color: rgb(253, 243, 227);text-align: center;font-size: 20px;margin-bottom: 12px;">
              <van-index-anchor :index="platform.title_tag">{{ platform.title }}</van-index-anchor>
            </div>
            <van-grid
              :border="false"
              :column-num="4"
              direction="horizontal"
              gutter="5"
              center
            >
              <van-grid-item v-for="(item,index3) in platform.list" :key="index3" :to="(item.redirect != null && item.redirect.length > 0)?'/'+item.redirect:'/recharge?id='+item.platform_id">
              <van-badge :content="item.tag">
                <div class="item_card">
                  <van-icon :name="item.icon" size="50"/>
                  <div style="margin-top:12px;font-weight:700;color:rgb(39,39,39);font-size:16px;">{{ item.platform_title }}</div>
                </div>
              </van-badge>
              </van-grid-item>
            </van-grid>
          </div>
    </van-index-bar>
  </div>
</template>

<script>
import { Grid, GridItem, Sticky, Tab, Tabs, Icon, Image as VanImage, Divider, IndexAnchor, IndexBar, Notify, Badge, Swipe, SwipeItem } from "vant";
import { get } from "@/utils/request";
import { reactive, toRefs, onMounted, ref, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
const { aplus_queue } = window;
import wx from "weixin-js-sdk";
window.wx = wx;

export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Sticky.name]: Sticky,
    [Grid.name]: Grid,
    [Badge.name]: Badge,
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [Divider.name]: Divider,
    [SwipeItem.name]: SwipeItem,
    [IndexAnchor.name]: IndexAnchor,
    [Swipe.name]: Swipe,
    [IndexBar.name]: IndexBar,
    [GridItem.name]: GridItem,
  },
  setup() {
    const tabsBar = ref();
    const router = useRouter();
    const state = reactive({
      loading: false,
      pageData: {
        type: [{'tag':'hot'}],
        all: {
          'hot': []
        },
        order_tips: []
      },
      index: 0,
      active: 0,
      state: {
        smsSendBtn: false,
        time: 60,
      },
      allMenuData: [],
      visible: false,
      wxminiapp: false,
      confirmLoading: false,
      searchType: "0",
      searchTitle: "",
      operateTitle: "编辑",
      setInterval: null,
      adsList: []
    });
    onMounted(() => {
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          state.wxminiapp = true
        }
      })
      getIndexData();
      const token = router.currentRoute.value.query.token;
      const openid = router.currentRoute.value.query.openid;
      let open_id = ''
      if (token !== undefined && token.length > 20) {
        localStorage.token = token
      }
      if (openid !== undefined && openid.length > 20) {
        localStorage.openid = openid
      open_id = openid
      } else {
        open_id = localStorage.getItem('openid')
      }
      setTimeout(function () {
        wx.checkJsApi({
          jsApiList: ['checkJsApi'],
          success(res) {
            console.log(res.checkResult) // 如果checkResult为true，则表示当前浏览器为微信浏览器
            if ((open_id == undefined || open_id.length < 10) && state.wxminiapp == false) {
              window.location.href = 'https://21ds.cn/mp_weixin/oauth?mw_id=MW4754391651808828&scope=snsapi_userinfo&redirect_uri=' + encodeURIComponent(window.location.href)
              }
          },
          fail(res) {
            console.log(res) // 初始化JS-SDK失败
          }
        })
      }, 350);
      window.AdsData('4929223276626699').then((res) => {
        state.adsList = res
      })
    });
    onBeforeUnmount(() => {
      if (state.setInterval) {
            clearInterval(state.setInterval)
          }
    });
    function getIndexData() {
      get("getIndex").then((response) => {
        if (response.code == 200) {
          state.pageData = response.data;
          localStorage.pv_user_id = response.data.pv_user_id
          localStorage.pv_user_name = response.data.pv_user_name
          aplus_queue.push({
          action: 'aplus.sendPV',
            arguments: [{
                is_auto: false
            }, {
                pv_user_id: response.data.pv_user_id,
                pv_user_name: response.data.pv_user_name
            }]
          });
          showToast();
        } else {
          Notify({ type: "danger", message: response.msg });
          return false;
        }
      });
    }
    function showToast() {
      const order_tips = state.pageData.order_tips
      var setIntervalId = setInterval(function () {
        var index = Math.floor((Math.random()*order_tips.length));
        var el = document.createElement("div");
            el.setAttribute("class", "web-toast");
            el.innerHTML = order_tips[index];
            document.body.appendChild(el);
            el.classList.add("fadeIn");
            el.classList.remove("fadeIn");
            el.classList.add("fadeOut");
            el.addEventListener("animationend", function () {
                document.body.removeChild(el);
            });
            el.addEventListener("webkitAnimationEnd", function () {
                document.body.removeChild(el);
            });
      },2500)
      state.setInterval = setIntervalId
    }
    function clickTabs(e) {
      tabsBar.value.scrollTo(state.pageData.type[e].tag);
      aplus_queue.push({
        action: 'aplus.record',
          arguments: ['indexTab', 'CLK', {
            title: state.pageData.type[e].title
          }]
        });
    }
    return {
      ...toRefs(state),
      tabsBar,
      clickTabs,
      getIndexData,
    };
  },
};
</script>

<style lang="less">
.van-swipe__track{
  width: 100vw;
}
.item_card {
  width: 100%;
  height: 140px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}.van-index-bar__sidebar{
  display: none;
}.van-index-anchor{
color: rgb(253, 243, 227);text-align: center;font-size: 20px;margin-bottom: 12px;top: 66px;
}.van-index-anchor {
    background: url(https://img.alicdn.com/imgextra/i4/573804794/O1CN01pqwGVP1lHhS3Lk9z7_!!573804794.png) no-repeat center;
}.van-grid-item__content{
  padding: 0px;
  border-radius: 6px;
}.van-badge {
    color: #fff;
    padding: 2px 8px;
    border: none;
    border-radius: 10px 0px;
    z-index: 9;
    background-image: linear-gradient(to right, #ff5730 , #ff8334);
    background: -moz-linear-gradient(right, #ff5730 0%, #ff8334 100%);
    background: -webkit-gradient(linear, left right, left bottom, color-stop(0%,#ff5730), color-stop(100%,#ff8334));
    background: -webkit-linear-gradient(right, #ff5730 0%,#ff8334 100%);
    background: -o-linear-gradient(right, #ff5730 0%,#ff8334 100%);
    background: -ms-linear-gradient(right, #ff5730 0%,#ff8334 100%);
    background: linear-gradient(to right, #ff5730 0%,#ff8334 100%);
}.van-grid-item {
    margin-bottom: 1px;
    }
       @keyframes fadeIn {
            0%    {opacity: 0}
            100%  {opacity: 1}
        }
        @-webkit-keyframes fadeIn {
            0%    {opacity: 0}
            100%  {opacity: 1}
        }
        @-moz-keyframes fadeIn {
            0%    {opacity: 0}
            100%  {opacity: 1}
        }
        @-o-keyframes fadeIn {
            0%    {opacity: 0}
            100%  {opacity: 1}
        }
        @-ms-keyframes fadeIn {
            0%    {opacity: 0}
            100%  {opacity: 1}
        }
        /*设置三帧让动画消失得更平缓*/
        @keyframes fadeOut {
            0%    {opacity: 1}
            60%     {opacity: .9}
            100%  {opacity: 0}
        }
        @-webkit-keyframes fadeOut {
            0%    {opacity: 1}
            60%     {opacity: .9}
            100%  {opacity: 0}
        }
        @-moz-keyframes fadeOut {
            0%    {opacity: 1}
            60%     {opacity: .9}
            100%  {opacity: 0}
        }
        @-o-keyframes fadeOut {
            0%    {opacity: 1}
            60%     {opacity: .9}
            100%  {opacity: 0}
        }
        @-ms-keyframes fadeOut {
            0%    {opacity: 1}
            60%     {opacity: .9}
            100%  {opacity: 0}
        }
        .web-toast{
            position: absolute;
            background: rgba(0, 0, 0, 0.7);
            color: #fff;
            font-size: 12px;
            line-height: 1;
            padding:10px;
            border-radius: 7px;
            right: .20rem;
            top: .15rem;
            // transform: translate(-50%,-50%);
            // -webkit-transform: translate(-50%,-50%);
            // -moz-transform: translate(-50%,-50%);
            // -o-transform: translate(-50%,-50%);
            // -ms-transform: translate(-50%,-50%);
            z-index: 9999;
            white-space: nowrap;
        }
        /*时间稍微比一般的提示时间长*/
        .fadeOut{
            animation: fadeOut 1.9s;
        }
        .fadeIn{
            animation:fadeIn 1s;
        }
</style>
